//font-family: 'Lato', sans-serif;
//font-family: 'Merriweather', serif;

$primaryColor: #f47920;

@keyframes scaleIn {
  0% {
    transform: scale(0) translateY(100px);
    opacity: 0;
  }
  30% {
    transform: scale(1.1) translateY(-20px);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
}

body {
  background: #000 url(../images/bg.png) center -100px / cover no-repeat;
  margin: 0;
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
  overflow-y: auto;
}

.wrapper {
  width: 1000px;
  margin: 0 auto;
  @media screen and (max-width: 1000px) {
    width: 600px;
  }
  @media screen and (max-width: 600px) {
    width: 400px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    padding: 0 20px;
  }
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: #fff;
  text-align: center;
  font-family: "Lato", sans-serif;
  position: relative;
  display: inline-block;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30px;
    width: 20px;
    height: 100%;
    background-color: $primaryColor;
  }
}

.header {
  height: 5rem;
  display: flex;
  align-items: center;
  @media screen and (max-width: 400px) {
    height: 4rem;
  }
  .logo {
    height: 60%;
    @media screen and (max-width: 400px) {
      margin: 0 auto;
    }
  }
  nav {
    margin: 0 auto;
    display: flex;
    align-items: center;
    @media screen and (max-width: 600px) {
      display: none;
    }
    > a {
      color: #fff;
      text-decoration: none;
      position: relative;
      &:hover {
        &:before {
          transform: scaleX(1);
        }
      }
      &:not(:last-child) {
        margin-right: 5rem;
      }
      &:before {
        content: "";
        position: absolute;
        height: 0.25rem;
        left: 0;
        right: 0;
        background: $primaryColor;
        bottom: -0.5rem;
        transform: scaleX(0);
        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      }
    }
  }
  .download-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    background-color: $primaryColor;
    color: #fff;
    font-family: "Lato", sans-serif;
    padding: 0 1rem;
    height: 2.5rem;
    text-transform: uppercase;
    border: 0;
    font-weight: bold;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}

.hero {
  margin-top: 5vh;
  display: flex;
  align-items: center;
  margin-bottom: 20vh;
  height: 80vh;
  animation: scaleIn 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  @media screen and (max-width: 1000px) {
    height: auto;
    width: 100%;
  }

  .image {
    height: 80%;
    position: relative;
    margin: 0 auto;
    transform-style: preserve-3d;
    @media screen and (max-width: 1000px) {
      height: auto;
      width: 100%;
      margin-left: -5vh;
    }
    > img {
      height: 100%;
      margin-left: -5vh;
      @media screen and (max-width: 1000px) {
        height: auto;
        width: 100%;
        margin-left: 0;
      }
    }
    .phone {
      height: 80%;
      top: 30%;
      right: -20%;
      position: absolute;
      transform: translateZ(4rem);
      @media screen and (max-width: 1000px) {
        height: auto;
        width: 40%;
        right: -15%;
      }
    }
    .hero-text {
      color: rgba(255, 255, 255, 0.5);
      font-family: "Lato", sans-serif;
      font-size: 1rem;
      text-align: right;
      margin-right: 20%;
      transform: translateZ(2rem);
      @media screen and (max-width: 1000px) {
        font-size: 0.75rem;
        margin-right: 25%;
      }
    }
  }
}

.lines {
  display: flex;
  align-items: flex-end;
  height: 10rem;
  margin-top: -5rem;
  .line {
    background-color: $primaryColor;
  }
}

.video-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15vh;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: -50%;
    left: 5%;
    right: 0;
    width: 95%;
    height: 200%;
    opacity: 0.5;
    filter: grayscale(1);
    background: url(../images/texture.png) center / contain no-repeat;
    @media screen and (max-width: 400px) {
      left: 0;
      background-size: cover;
    }
  }
  video {
    width: 800px;
    height: 450px;
  }
}

.available-cars {
  margin-bottom: 10rem;
  .title-wrapper {
    margin-bottom: 2rem;
  }
  .marquee {
    color: #fff;
    overflow: hidden;
    //display: flex;
    //align-items: center;
    > div {
      > div {
        margin: 0 !important;
      }
    }
  }
  .car-logo {
    height: 5rem;
    margin: 0 2rem;
    @media screen and (max-width: 400px) {
      height: 2rem;
      margin: 0 0.5rem;
    }
  }
}

.feature-wrapper {
  margin-bottom: 5rem;
  .feature-item-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .feature-item {
      flex-basis: calc(100% / 3);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 4rem;
      @media screen and (max-width: 1000px) {
        flex-basis: calc(100% / 2);
      }
      @media screen and (max-width: 400px) {
        flex-basis: 100%;
      }
      //&:not(:last-child) {
      //  margin-bottom: 4rem;
      //}
      .feature-image {
        width: 70%;
        margin-bottom: 1rem;
        > img {
          width: 100%;
        }
      }
      .feature-text {
        width: 70%;
        color: #fff;
        font-size: 1rem;
      }
    }
  }
}

.download-wrapper {
  text-align: center;
  margin-bottom: 10rem;
  .helper {
    color: #999;
  }
  .download-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 4rem;
      @media screen and (max-width: 400px) {
        height: 2rem;
      }
      &.play-store {
        height: 6rem;
        @media screen and (max-width: 400px) {
          height: 3rem;
        }
      }
    }
  }
}

.distributor-wrapper {
  margin-bottom: 5rem;
  .distributor-item-wrapper {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap;
  }
  .distributor-item {
    flex-basis: calc(100% / 4);
    color: #fff;
    margin: 0 auto;
    @media screen and (max-width: 1000px) {
      flex-basis: calc(100% / 3);
    }
    @media screen and (max-width: 400px) {
      flex-basis: 80%;
      margin: 0 auto;
      text-align: center;
    }
    .distributor-logo-wrapper {
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media screen and (max-width: 400px) {
        justify-content: center;
      }
    }
    .distributor-logo {
      width: 80%;
      &.turboinside {
        width: 40%;
      }
    }

    h3 {
      font-size: 1rem;
    }
    p {
      color: #999;
      font-size: 0.75rem;
    }
  }
}

footer {
  height: 3rem;
  background-color: $primaryColor;
  color: #fff;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  @media screen and (max-width: 400px) {
    font-size: 0.75rem;
  }
}
